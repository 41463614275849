import Ajax from './Ajax'

const Customer = {
  getCustomersDatatables: () => {
    let uri = '/customers-datatables';
    return Ajax.get(uri);
  },
  getCustomer: (id) => {
    return Ajax.get(`/customers/${id}`)
  },
  postCustomers: (params) => {
    return Ajax.post('/customers', params)
  },
  putCustomers: (params) => {
    return Ajax.put('/customers/' + params.id, params)
  },
  delCustomers: (id) => {
    return Ajax.delete('/customers/' + id)
  }
}

export default Customer
