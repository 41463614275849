<template>
  <div class="container">
    <b-row>
      <b-col>
        <h1 v-if="$route.params.id !== 'new'">Customer #{{ $route.params.id }}</h1>
        <h1 v-else>New Customer</h1>
      </b-col>
    </b-row>
    <div class="row">
      <div class="col-sm-6 col-lg-6">
        <b-form-group>
          <label class="form-label">Name</label>
          <b-form-input
            v-model="form.name"
            required
            type="text"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-sm-6 col-lg-6">
        <b-form-group>
          <label class="form-label">Email</label>
          <b-form-input
            v-model="form.email"
            required
            type="email"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>

    <b-form-group>
      <label class="form-label">Wallet Address</label>
      <b-form-input
        v-model="form.walletAddress"
        type="text"
      ></b-form-input>
    </b-form-group>

    <div v-if="isWeb2UserConnected && getWeb2ActiveAccount && getWeb2ActiveAccount.role.name === 'Administrator'"
         class="d-flex justify-content-end">
      <button class="btn btn-primary mr-2" @click.prevent="onSubmit">Save</button>
      <router-link class="btn btn-danger" to="/customers">Cancel</router-link>
    </div>
  </div>
</template>

<script>

import Customer from '../../api/Customer'
// import {create, all} from 'mathjs'
import {mapGetters} from "vuex";
// import LineChart from "../Chart/LineChart.js";
// import Swal from "sweetalert2";

export default {
  name: 'CustomerFormComponent',
  computed: {
    ...mapGetters("user", ["isWeb2UserConnected", "getWeb2ActiveAccount"]),
  },

  data() {
    return {
      form: {
        id: null,
        name: '',
        email: '',
        walletAddress: ''
      },
    }
  },
  created() {
    const id = this.$route.params.id;
    if (id !== 'new') {
      Customer.getCustomer(id)
        .then((response) => {
          this.form = response.data
          console.log({response})
        }).catch((error) => {
        console.log(error)
      })
    } else {
      this.form = {}
    }
  },

  methods: {
    onSubmit() {
      if (!this.form.id) {
        Customer.postCustomers({...this.form, username: this.form.email})
          .then(() => {
            this.$router.push({path: '/customers'})
            this.$toasted.show('The new customer has been added', {
              type: 'success',
              duration: 5000,
              theme: "bubble",
              position: "top-center"
            });
          }).catch((error) => {
          console.log(error)
        })
      } else {
        Customer.putCustomers(this.form)
          .then(() => {
            this.$router.push({path: '/customers'})
            this.$toasted.show('The customer has been updated', {
              type: 'success',
              duration: 5000,
              theme: "bubble",
              position: "top-center"
            });
          }).catch((error) => {
          console.log(error)
        })
      }
    },

  }
}
</script>

<style scoped>

</style>
